import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';
import { fetchBloc } from '../../actions/restAction';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class SlideRaisons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: "0"
    }

    this.changeSlide = this.changeSlide.bind(this)
  }

  componentDidMount() {
    this.props.fetchSlidesRaisons(this.props.fetched)
  }

  changeSlide(position) {
    this.setState({
      currentSlide: position
    })
  }

  renderSlide(slide) {
    return (
      <div key={slide.uuid} className="container">
        <div className="row slide">
          <div className="col-sm-5 offset-sm-1">
            <h2>{ReactHtmlParser(slide.title)}</h2>
            {ReactHtmlParser(slide.body)}
          </div>

          <div className="col-sm-5 text-right">
            <img src={process.env.REACT_APP_API_HOST + slide.field_picture} className="img-fluid" alt={slide.field_picture_alt} />
          </div>
        </div>
      </div>
    )
  }

  renderSlideControls() {
    if(this.props.slides.length === 0) {
      return
    }

    return (
      <div className="row controls">
        <div className="col-sm-10 offset-sm-1">

          {
            this.props.slides.map(slide => (
              <button key={slide.uuid} onClick={() => this.changeSlide(slide.weight)} className={"btn btn-link" + ((slide.weight === this.state.currentSlide) ? " active" : "")}>{"0"+(parseInt(slide.weight)+1)}</button>
            ))
          }


        </div>
      </div>
    )
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: false,
      mobileFirst: true,
      appendDots: dots => (
        <div>
          <div className="row">
            <div className="col-sm-5 offset-sm-1">
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          </div>
        </div>
      ),
      customPaging: i => (
        <div className="button">{"0" + (i + 1)}</div>
      ),
      responsive: [
        {
          breakpoint: 576,
          settings: {
            adaptiveHeight: true,
          }
        }
      ]      
    };

    return (
      <div id="bloc-raisons" className="ambition bloc-type-1">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <h1>{ReactHtmlParser(this.props.data.title)}</h1>
              <div className="chapo">
                {ReactHtmlParser(this.props.data.body)}
              </div>
            </div>
          </div>

          <Slider {...settings}>
          {
            this.props.slides.map(this.renderSlide)
          }
          </Slider>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fetched: state.blocs.fetched,
    slides: state.blocs.blocs.filter(item => item.field_nom === "slide_raison" && item.langcode === state.locale.lang),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchSlidesRaisons: (fetched) => dispatch(fetchBloc('slide_raison', fetched))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SlideRaisons))