import { LOCALE_SET } from "../types";
import { push } from 'connected-react-router'

export const localeSet = lang => ({
  type: LOCALE_SET,
  lang
});

export const setLocale = lang => dispatch => {
  localStorage.language = lang;
  dispatch(localeSet(lang));
};

export const switchLocale = (lang, path) => dispatch => {
  setLocale(lang)(dispatch)
  dispatch(push(path))
};