import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

export default class Saurel extends Component {
  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    return (
      <div id="bloc-saurel">
        <div className="container" style={style}>
          <div className="row">

            <div className="col-lg-6">
              <div className="presentation">
                <h2>{this.props.data.title}</h2>
                <div className="quote">
                  {ReactHtmlParser(this.props.data.body)}
                </div>
                <em>{this.props.data.field_sous_titre}</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
