import {FETCH_PRESSE_PENDING, FETCH_PRESSE_REJECTED, FETCH_PRESSE_FULFILLED} from '../types'

const initialState = {
    fetching: true,
    rejected: false,
    items: []
}

const presse = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRESSE_PENDING:
            return {
                ...state,
                fetching: true,
                rejected: false,
                items: []
            }
        case FETCH_PRESSE_REJECTED:
            return {
                ...state,
                fetching: false,
                rejected: true,
                items: []
            }

        case FETCH_PRESSE_FULFILLED:
            return {
                ...state,
                fetching: false,
                rejected: false,
                items: action.payload.data
            }

        default:
            return state;
    }
}

export default presse
