import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import CookieConsent from "react-cookie-consent";

class IntlCookieConsent extends Component {
    render() {
        return (
            <CookieConsent
                location="bottom"
                buttonText="Ok"
                style={{ background: "#174395", textAlign: 'center' }}
                buttonStyle={{ background: "#739155" }}
            >
                {this.props.intl.formatMessage({ id: 'cookie.consent' })}
            </CookieConsent>
        )

    }
}

export default injectIntl(IntlCookieConsent)