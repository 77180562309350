import {FETCH_BLOC_PENDING, FETCH_BLOC_REJECTED, FETCH_BLOC_FULFILLED} from '../types'

const initialState = {
    fetching: true,
    rejected: false,
    fetched: [],
    blocs: []
}

const blocs = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BLOC_PENDING:
            return {
                ...state,
                fetching: true,
                rejected: false,
            }
        case FETCH_BLOC_REJECTED:
            return {
                ...state,
                fetching: false,
                rejected: true,
            }

        case FETCH_BLOC_FULFILLED:
            const type = (action.payload.data[0]) ? action.payload.data[0].field_nom : ''

            return {
                ...state,
                fetching: false,
                rejected: false,
                fetched: [...state.fetched, type],
                blocs: [...state.blocs, ...action.payload.data]
            }

        default:
            return state;
    }
}

export default blocs
