import React, { Component } from 'react'
import ModalVideo from 'react-modal-video'
import ReactHtmlParser from 'react-html-parser'

export default class Video extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({isOpen: true})
  }

  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    const regex1 = RegExp('v=(.*)','g');
    let array1;
    let videoId = ''
    
    if ((array1 = regex1.exec(this.props.data.field_lien_uri)) !== null) {
      //console.log(`Found ${array1[1]}. Next starts at ${regex1.lastIndex}.`);
      videoId = array1[1]
    }

    return (
      <div id="bloc-video" style={style}>
        <div id="play" onClick={this.openModal}>
          <img src={process.env.REACT_APP_API_HOST + this.props.data.field_illustration} alt={this.props.data.field_illustration_alt} />
        </div>
        <h1>{ReactHtmlParser(this.props.data.title)}</h1>

        <ModalVideo channel='youtube' controls='0' showinfo='0' isOpen={this.state.isOpen} videoId={videoId} onClose={() => this.setState({isOpen: false})} />
      </div>
    )
  }
}