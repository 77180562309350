import HomePage from './component/HomePage'
import Pacte from './component/Pacte'
import PressRoom from './component/PressRoom'
import Mentions from './component/Mentions'
import Faq from './component/Faq'
import Credits from './component/Credits'
import Cookies from './component/Cookies'
import Program from './component/Program'
import Register from './component/Register'
import Speakers from './component/Speakers'

export default {
  "homepage": {
    component: HomePage,
    exact: true,
    intl: {
      fr: "/",
      en: "/en/",
      es: "/es/"
    }
  },
  "newsroom": {
    component: PressRoom,
    intl: {
      fr: "/fr/espace-presse",
      en: "/en/news-room",
      es: "/es/news-room"
    }
  },
  "thepacte": {
    component: Pacte,
    intl: {
      fr: "/fr/le-pacte",
      en: "/en/the-pacte",
      es: "/es/el-pacto"
    }
  },
  "faq": {
    component: Faq,
    intl: {
      fr: "/fr/faq",
      en: "/en/faq",
      es: "/es/faq"
    }
  },
  "terms": {
    component: Mentions,
    intl: {
      fr: "/fr/mentions-legales",
      en: "/en/terms-of-use",
      es: "/es/condiciones-legales"
    }
  },
  "credits": {
    component: Credits,
    intl: {
      fr: "/fr/credits",
      en: "/en/credits",
      es: "/es/creditos"
    }
  },
  "cookies": {
    component: Cookies,
    intl: {
      fr: "/fr/cookies",
      en: "/en/cookies",
      es: "/es/cookies"
    }
  },
  "program": {
    component: Program,
    exact: true,
    intl: {
      fr: "/fr/programme",
      en: "/en/program",
      es: "/es/programa"
    }
  },
  "speakers": {
    component: Speakers,
    intl: {
      fr: "/fr/programme/intervenants",
      en: "/en/programme/speakers",
      es: "/es/programme/expertos"
    }
  },  
  "register": {
    component: Register,
    intl: {
      fr: "/fr/inscription",
      en: "/en/register",
      es: "/es/me-inscribo"
    }
  },
  "webform": {
    intl: {
      fr: 'webform_rest/contact/fields',
      en: 'en/webform_rest/contact/fields',
      es: "es/webform_rest/contact/fields"
    }
  },

  "inscription_elu": {
    intl: {
      fr: 'webform_rest/inscription_elu/fields',
      en: 'en/webform_rest/inscription_elu/fields',
      es: "es/webform_rest/inscription_elu/fields"
    }
  },

  "inscription_prive": {
    intl: {
      fr: 'webform_rest/inscription_prive/fields',
      en: 'en/webform_rest/inscription_prive/fields',
      es: "es/webform_rest/inscription_prive/fields"
    }
  },

  "inscription_presse": {
    intl: {
      fr: 'webform_rest/inscription_presse/fields',
      en: 'en/webform_rest/inscription_presse/fields',
      es: "es/webform_rest/inscription_presse/fields"
    }
  },

  "inscription_autre": {
    intl: {
      fr: 'webform_rest/inscription_autre/fields',
      en: 'en/webform_rest/inscription_autre/fields',
      es: "es/webform_rest/inscription_autre/fields"
    }
  },  

  "webform_submit": {
    intl: {
      fr: 'webform_rest/submit',
      en: 'en/webform_rest/submit',
      es: "es/webform_rest/submit"
    }
  },  
};
