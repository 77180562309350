import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser'
import { fetchBloc } from '../../actions/restAction';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class SlideEngagements extends Component {

  componentDidMount() {
    this.props.fetchSlidesRaisons(this.props.fetched)
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
          }
        }
      ]        
    };

    return (
      <div id="bloc-engagement">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <h1>{ReactHtmlParser(this.props.data.title)}</h1>
            </div>
          </div>

          <div className="container">
            <Slider {...settings} className="slick-engagements">
            {
              this.props.slides.map(slide => {
                return (
                  <div key={slide.uuid} className="container-slides">
                    <div className="row no-gutters">
                      <div className="col-sm-5">
                        <div className="picture">
                          <img src={process.env.REACT_APP_API_HOST + slide.field_picture} alt={slide.field_picture_alt} />
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <div className="flex">
                          <div className="text">
                            <h2>{slide.title}</h2>
                            {ReactHtmlParser(slide.body)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fetched: state.blocs.fetched,
    slides: state.blocs.blocs.filter(item => item.field_nom === "slide_engagement" && item.langcode === state.locale.lang),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchSlidesRaisons: (fetched) => dispatch(fetchBloc('slide_engagement', fetched))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SlideEngagements))