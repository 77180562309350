import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import logo from '../assets/img/logo-footer.png'

class Footer extends Component {
  render() {
    const { intl } = this.props

    return (
      <footer className="d-flex align-items-center">
        <div className="container align-self-center">
          <div className="row">
            <div className="col-md-3 left">
              <img src={logo} alt="Montpellier Méditéranée Métropole" />
            </div>
            <div className="col-md-5 center">
              <span className="d-none d-md-inline">Copyright</span> &copy; 2018 - Montpellier Méditerranée Métropole
            </div>
            <div className="col-md-4 right">
              <Link to={intl.formatMessage({ id: "route.credits" })} className="nav-footer align-self-center">{intl.formatMessage({ id: "footer.credits" })}</Link>
              <Link to={intl.formatMessage({ id: "route.terms" })} className="nav-footer align-self-center">{intl.formatMessage({ id: "footer.terms" })}</Link>
              <Link to={intl.formatMessage({ id: "route.cookies" })} className="nav-footer align-self-center">{intl.formatMessage({ id: "footer.cookies" })}</Link>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default injectIntl(Footer)
