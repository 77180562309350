import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'

import thunk from "redux-thunk";
import promise from 'redux-promise-middleware'

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'

import './config'
import './sass/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import reducers from './reducers'
import { localeSet } from './actions/locale'
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import es from "react-intl/locale-data/es";

import { createBrowserHistory } from "history";

const history = createBrowserHistory();

addLocaleData([...en, ...fr, ...es]);

const middleware = [
  thunk,
  promise()
]

const store = createStore(
  reducers(history),
  (process.env.NODE_ENV === "development") ?
    composeWithDevTools(applyMiddleware(routerMiddleware(history), ...middleware)):
    applyMiddleware(routerMiddleware(history), ...middleware)
)

let locationLanguage = 'fr'

history.listen((location, action) => {
  window.scrollTo(0, 0);
  
  const button = document.querySelector('.navbar-toggler');
  if(!button.classList.contains('collapsed')) {
    button.click();
  }
});

if(history.location.pathname.match(/^\/en\/?/)) {
  locationLanguage = 'en'
}else if(history.location.pathname.match(/^\/es\/?/)) {
  locationLanguage = 'es'
}

store.dispatch(localeSet(locationLanguage));

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route component={App} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
