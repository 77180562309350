import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { fetchBloc } from '../actions/restAction'
import { retrieveBloc } from '../bloc'

import Welcome from './blocs/Welcome'
import Saurel from './blocs/Saurel'
import Video from './blocs/Video'
import SlideRaisons from './blocs/SlideRaisons'
import Venir from './blocs/Venir'
import Instagram from './blocs/Instagram'
import ABientot from './blocs/ABientot'
import Contact from './blocs/Contact'
import Objectifs from './blocs/Objectifs';
import MapMonde from './blocs/MapMonde';
import Sala from './blocs/Sala';

const blocs = ['welcome', 'saurel', 'sala', 'video', 'raisons', 'venir', 'abientot', 'instagram', 'objectifs', 'mapmonde'/*, 'sponsors'*/]

class HomePage extends Component {
  componentDidMount() {
    blocs.forEach(item => this.props.fetchBloc(item, this.props.fetched))
    this.props.setHeaderOpacity()
  }

  render() {
    //blocs
    const { welcome, saurel, sala, video, raisons, venir, instagram, abientot, objectifs, mapmonde/*, sponsors*/} = this.props

    //i18n
    const { intl } = this.props

    if (welcome === undefined) {
      return <div className="loading"><i className="fas fa-spin fa-spinner-third"></i>{intl.formatMessage({ id: "loading" })}</div>
    }

    return (
      <div className="homepage">
        <Welcome data={welcome} />
        <Saurel data={saurel} />
        <Sala data={sala} />
        <Video data={video} />
        <Objectifs data={objectifs} />
        <MapMonde data={mapmonde} />
        <SlideRaisons data={raisons} />
        <Venir data={venir} />
        <Instagram data={instagram} />
        <ABientot data={abientot} />
        <Contact />
      </div>
      )
  }
}

function mapStateToProps(state) {
  let map = {
    fetched: state.blocs.fetched
  }
  blocs.forEach(item => map[item] = retrieveBloc(item, state))
  return map
}

const mapDispatchToProps = (dispatch) => ({
  fetchBloc: (bloc, fetched) => dispatch(fetchBloc(bloc, fetched)),
  setHeaderOpacity: () => dispatch({type: 'SET_TRANSPARENT'}),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HomePage))