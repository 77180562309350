import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ReactHtmlParser from 'react-html-parser';

import { fetchBloc, fetchFaqs, fetchFaqsCategories } from '../actions/restAction'
import { retrieveBloc } from '../bloc'

class Faq extends Component {

  componentDidMount() {
    this.props.fetchFaqsCategories()
    this.props.fetchFaqs()
    this.props.fetchHeader(this.props.fetched)
    this.props.setHeaderOpacity()
  }

  render() {
    const { categories, questions, header } = this.props

    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + header.field_picture + ')',
    };
    

    return (
      <div id="page-faq">
        <div className="intro d-flex align-items-center" style={style}>
          <div className="container">
            <h1>{ReactHtmlParser(header.title)}</h1>
            <h2>{ReactHtmlParser(header.field_sous_titre)}</h2>
          </div>
        </div>

        <div className="container">
          {
            categories.map(
              category => {
                return (
                  <div className="row" key={category.uuid}>
                    <div className="col-sm-3">
                      <h3>{ReactHtmlParser(category.name)}</h3>
                    </div>
                    <div className="questions col offset-sm-1">
                      {
                        questions.filter(question => question.category === category.id).map(
                          question => {
                            return (
                              <div className="question" key={question.uuid}>
                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={`#collapse${question.uuid}`}>
                                  {ReactHtmlParser(question.title)}
                                </button>
                                <div id={`collapse${question.uuid}`} className="reponse collapse">
                                  {ReactHtmlParser(question.body)}
                                </div>
                              </div>
                            )
                          }
                        )
                      }
                    </div>
                  </div>
                )
              }
            )
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    lang: state.locale.lang,
    questions: state.faqs.items.filter(item => item.langcode === state.locale.lang),
    categories: state.faqs_categories.items.filter(item => item.langcode === state.locale.lang),
    fetched: state.blocs.fetched,
    header: retrieveBloc('header_faq', state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchFaqs: () => dispatch(fetchFaqs()),
  fetchFaqsCategories: () => dispatch(fetchFaqsCategories()),
  setHeaderOpacity: () => dispatch({ type: 'SET_OPAQUE' }),
  fetchHeader: (fetched) => dispatch(fetchBloc('header_faq', fetched))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Faq))