import {FETCH_INSTAGRAM_PENDING, FETCH_INSTAGRAM_REJECTED, FETCH_INSTAGRAM_FULFILLED} from '../types'

const initialState = {
    fetching: true,
    rejected: false,
    items: []
}

const instagrams = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INSTAGRAM_PENDING:
            return {
                ...state,
                fetching: true,
                rejected: false,
                items: []
            }
        case FETCH_INSTAGRAM_REJECTED:
            return {
                ...state,
                fetching: false,
                rejected: true,
                items: []
            }

        case FETCH_INSTAGRAM_FULFILLED:
            return {
                ...state,
                fetching: false,
                rejected: false,
                items: action.payload.data
            }

        default:
            return state;
    }
}

export default instagrams
