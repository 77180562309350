import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { fetchBloc } from '../actions/restAction';
import { retrieveBloc } from '../bloc'
import HeaderPacte from './blocs/HeaderPacte';
import SlideEngagements from './blocs/SlideEngagements';
import Mufpp from './blocs/Mufpp';
import Vision from './blocs/Vision';
import Ambition from './blocs/Ambition';
import ABientot from './blocs/ABientot';
import Contact from './blocs/Contact';

const blocs = ['header_pacte','engagement','mufpp','vision','ambition','abientot']

class Pacte extends Component {
  componentDidMount() {
    blocs.forEach(item => this.props.fetchBloc(item, this.props.fetched))
    this.props.setHeaderOpacity()
  }

  render() {
    //blocs
    const { header_pacte, engagement, mufpp, vision, ambition, abientot } = this.props

    return (
      <div className="page-pacte">
        <HeaderPacte data={header_pacte} />
        <SlideEngagements data={engagement} />
        <Mufpp data={mufpp} />
        <Vision data={vision} />
        <Ambition data={ambition} />
        <ABientot data={abientot} />
        <Contact />
      </div>
    )
  }
}

function mapStateToProps(state) {
  let map = {
    fetched: state.blocs.fetched
  }
  blocs.forEach(item => map[item] = retrieveBloc(item, state))
  return map
}

const mapDispatchToProps = (dispatch) => ({
  fetchBloc: (bloc, fetched) => dispatch(fetchBloc(bloc, fetched)),
  setHeaderOpacity: () => dispatch({type: 'SET_OPAQUE'}),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Pacte))