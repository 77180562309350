import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'
import { fetchBloc } from '../../actions/restAction';

class Vision extends Component {
  componentDidMount() {
    this.props.fetchVignettes(this.props.fetched)
  }

  renderVignette(position) {
    const { vignettes } = this.props

    if (vignettes.length === 0) {
      return <img src="//via.placeholder.com/500x350" className="img-fluid" alt="" />
    }

    const vignette = vignettes.filter(item => parseInt(item.weight) === position-1)[0]
    
    const style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + vignette.field_picture + ')',
    };

    return (
      <div className="vignette" style={style}>
        <span className="big">{ReactHtmlParser(vignette.title)}</span>
        <span className="small">{ReactHtmlParser(vignette.field_sous_titre)}</span>
      </div>
    )
  }

  render() {

    return (
      <div id="bloc-vision" className="bloc-type-1">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <h1>{ReactHtmlParser(this.props.data.title)}</h1>
              <div className="chapo">
                {ReactHtmlParser(this.props.data.body)}
              </div>

              <div className="row row-1">
                <div className="col-lg-6 offset-image">
                  {this.renderVignette(1)}
                </div>
                <div className="col-lg-6">
                  {this.renderVignette(2)}
                </div>
              </div>

              <div className="row row-2">
                <div className="col-lg-6 d-flex justify-content-center align-items-end">
                  <a href={this.props.data.field_lien_uri} className="btn btn-square btn-milan" rel="noopener noreferrer" target="_blank">{this.props.data.field_lien_title}</a>
                </div>
                <div className="col-lg-6">
                  {this.renderVignette(3)}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fetched: state.blocs.fetched,
    vignettes: state.blocs.blocs.filter(item => item.field_nom === "slide_vision" && item.langcode === state.locale.lang),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchVignettes: (fetched) => dispatch(fetchBloc('slide_vision', fetched))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Vision))