import axios from 'axios'

import {FETCH_BLOC, FETCH_FAQS, FETCH_FAQS_CATEGORIES, FETCH_TERMS, FETCH_INSTAGRAM, FETCH_PRESSE, FETCH_SPONSORS, FETCH_SPEAKERS} from '../types'

export const fetchBloc = (bloc, fetched) => {
    if(fetched.includes(bloc)) {
        return {
            type: "null"
        }
    }

    return {
        type: FETCH_BLOC,
        payload: axios.get(`/v1/bloc/${bloc}`)
    }
}

export const fetchFaqs = () => {
    return {
        type: FETCH_FAQS,
        payload: axios.get(`/v1/faq`)
    }
}

export const fetchFaqsCategories = () => {
    return {
        type: FETCH_FAQS_CATEGORIES,
        payload: axios.get(`/v1/faq/categories`)
    }
}

export const fetchInstagram = () => {
    return {
        type: FETCH_INSTAGRAM,
        payload: axios.get(`/v1/instagram`)
    }
}

export const fetchSponsors = () => {
    return {
        type: FETCH_SPONSORS,
        payload: axios.get(`/v1/sponsors`)
    }
}
export const fetchCookies = () => {
    return {
        type: FETCH_TERMS,
        payload: axios.get(`/v1/page/cookies`)
    }
}

export const fetchTerms = () => {
    return {
        type: FETCH_TERMS,
        payload: axios.get(`/v1/page/terms`)
    }
}

export const fetchCredits = () => {
    return {
        type: FETCH_TERMS,
        payload: axios.get(`/v1/page/credits`)
    }
}

export const fetchPress = () => {
    return {
        type: FETCH_PRESSE,
        payload: axios.get(`/v1/presse`)
    }
}

export const fetchSpeakers = () => {
    return {
        type: FETCH_SPEAKERS,
        payload: axios.get(`/v1/intervenants`)
    }
}