export function retrieveBloc(bloc, state) {
  const result = state.blocs.blocs.filter(item => (item.langcode === state.locale.lang && item.field_nom === bloc))[0]

  return (result) ? result : {
    uuid:                    "",
    langcode :               "",
    field_nom :              "",
    title :                  "",
    field_sous_titre :       "",
    body :                   "",
    field_lien_uri :         "",
    field_lien_title :       "",
    field_lien_options :     "",
    field_picture :          "",
    field_picture_alt :      "",
    field_illustration :     "",
    field_illustration_alt : "",
  }
}

export function retrieveBlocs(bloc, state) {
  const result = state.blocs.blocs.filter(item => (item.langcode === state.locale.lang && item.field_nom === bloc))

  return (result) ? result : []
}