import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Slider from "react-slick";
import { fetchBloc } from '../../actions/restAction';
import ReactHtmlParser from 'react-html-parser'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Ambition extends Component {
  componentDidMount() {
    this.props.fetchSlides(this.props.fetched)
  }

  render() {
    if(this.props.slides.length === 0) {
      return "Loading"
    }

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      adaptiveHeight: true,
    };

    return (
      <div id="bloc-ambition" className="bloc-type-1">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <h1>{this.props.data.title}</h1>
              <div className="chapo">
                {ReactHtmlParser(this.props.data.body)}
              </div>
            </div>
          </div>
          <Slider {...settings} className="slick-ambition">
          {
            this.props.slides.map(slide => {
              return (
                <div key={slide.uuid}>
                  <div className="row">    
                    <div className="col-sm-5 offset-sm-1">
                      <img src={process.env.REACT_APP_API_HOST + slide.field_picture} alt={slide.field_picture_alt} />
                    </div>   
                    <div className="col-sm-5">
                      <h2>{ReactHtmlParser(slide.title)}</h2>
                      {ReactHtmlParser(slide.body)}
                    </div>
                  </div>
                </div>
              )
            })
          }
          </Slider>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fetched: state.blocs.fetched,
    slides: state.blocs.blocs.filter(item => item.field_nom === "slide_ambition" && item.langcode === state.locale.lang),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchSlides: (fetched) => dispatch(fetchBloc('slide_ambition', fetched))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Ambition))