import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

export default class Objectifs extends Component {
  render() {

    return (
      <div id="bloc-objectifs">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="presentation">
                <div className="quote">
                  {ReactHtmlParser(this.props.data.body)}
                </div>
                <h1>{this.props.data.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
