import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

export default class MapMonde extends Component {

  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    return (
      <div id="bloc-mapmonde">
        <div className="container">
          <div className="row" style={style}>
            <div className="col">
              <div className="flex">
                <div className="cta">
                  {ReactHtmlParser(this.props.data.body)}
                  <a href={this.props.data.field_lien_uri} className="btn btn-square btn-black-border " rel="noopener noreferrer" target="_blank">{this.props.data.field_lien_title}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}