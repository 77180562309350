export default {
  en: {
    "loading": "Loading",

    "header.sitename": "MUFPP",
    "header.homepage": "Home",
    "header.thepacte": "The Pact",
    "header.newsroom": "Newsroom",
    "header.faq": "FAQ",
    "header.program": "Agenda",
    "header.register": "Register",
    "header.speakers": "Speakers",

    "footer.credits": "Credits",
    "footer.terms": "Terms",
    "footer.cookies": "Cookies",

    "contact.title": "Contact us",
    "contact.location1": "Le Corum Montpellier",
    "contact.location2": "Esplanade Charles De Gaulle 34000 Montpellier",
    "contact.location3": "Tramway line 1, stop Corum",

    "press.title": "Downloads",
    "press.download": "Download",

    "days": "D",
    "cookie.consent": "This website uses cookies to enhance the user experience.",

    "program.file": "/files/2019.09.09_provisional_agenda_5th_MUFPP_Annual_gathering_and_Mayors_Summit_2019.pdf",

    "register.participant": "Participant",
    "register.thank": "THANK YOU ! Your registration request has been taken into account. Our teams will come back to you once your registration is validated. You will then be able to definitely sign up for the different highlights!<br /><br />Do you have a particular request? Don’t hesitate to contact us by filling out the contact form.<br /><br />See you soon in Montpellier!",
    "register.error": "Oops !<br />Seems like you miss a field in the form.",
    "register.required": "Field is required.",
    "register.wrong_count": "Please choose exactly 2 items."
  },
  fr: {
    "loading": "Chargement en cours",

    "header.sitename": "MUFPP",
    "header.homepage": "Accueil",
    "header.thepacte": "Le Pacte",
    "header.newsroom": "Presse",
    "header.faq": "FAQ",
    "header.program": "Programme",
    "header.register": "Je m'inscris",
    "header.speakers": "Intervenants",

    "footer.credits": "Crédits",
    "footer.terms": "Mentions légales",
    "footer.cookies": "Cookies",

    "contact.title": "Contactez-nous",
    "contact.location1": "Le Corum Montpellier",
    "contact.location2": "Esplanade Charles De Gaulle 34000 Montpellier",
    "contact.location3": "Tramway ligne 1, arrêt Corum",

    "press.title": "Téléchargements",
    "press.download": "Télécharger",

    "program.file": "/files/2019.09.09_programme_prévisionnel_de_la_5ème_Rencontre_et_Sommet_des_Maires_du_MUFPP_2019.pdf",

    "days": "J",
    "cookie.consent": "En continuant votre navigattion sur ce site vous acceptez l'utilisation des cookies",

    "register.participant": "Participant",
    "register.thank": "MERCI ! <br />Votre demande d'inscription à bien été prise en compte. <br />Nos équipes reviendront vers vous une fois que votre inscription sera validée. Vous pourrez alors vous inscrire définitivement aux différents temps forts !<br /><br />Vous avez une demande particulière ? N'hésitez pas à nous contacter en remplissant le formulaire de contact.<br /><br />À bientôt à Montpellier !",
    "register.error": "Oops !<br />Il semblerait que vous ayez oublié de remplir un champ.",
    "register.required": "Le champ est requis.",
    "register.wrong_count": "Merci de choisir exactement 2 éléments."
  },
  es: {
    "loading": "Cargando",

    "header.sitename": "MUFPP",
    "header.homepage": "Portada",
    "header.thepacte": "El Pacto",
    "header.newsroom": "Prensa",
    "header.faq": "FAQ",
    "header.program": "Programa",
    "header.register": "Me Inscribo",
    "header.speakers": "Expertos", 

    "footer.credits": "Creditos",
    "footer.terms": "Condiciones Legales",
    "footer.cookies": "Cookies",

    "contact.title": "Contáctenos",
    "contact.location1": "El Corum Montpellier",
    "contact.location2": "Esplanade Charles De Gaulle 34000 Montpellier",
    "contact.location3": "Tranvía línea 1, parada Corum",

    "press.title": "Descargas",
    "press.download": "Descargar",

    "program.file": "/files/2019.09.09_programa_previsional_de_la_V_Reunion_Anual_y_Cumbre_de_los_Alcades_del_MUFPP_2019.pdf",

    "days": "D",
    "cookie.consent": "Al continuar su navegación en este sitio acepta el uso de cookies",

    "register.participant": "Participante",
    "register.thank": "GRACIAS ! Su solicitud de registro ha sido recibida. Nuestros equipos le responderán una vez que se valide su registro. ¡Entonces podrás inscribirse definitivamente en los diferentes momentos destacados !<br /><br />¿Tiene una pregunta particular? No dude en contactarnos rellenando el formulario de contacto.<br /><br />¡Hasta pronto en Montpellier!",
    "register.error": "¡Uy!<br />Parece que se pierde un campo en el formulario.",
    "register.required": "El campo es obligatorio.",
    "register.wrong_count": "Please choose exactly 2 items."
  },
};
