import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { fetchInstagram } from '../../actions/restAction';

class Instagram extends Component {

  componentDidMount() {
    this.props.fetchInstagram()
  }

  renderPictures() {
    if (this.props.pictures.length === 0) {
      return
    }

    return this.props.pictures.map(picture => (
      <div key={picture.uuid} className="col-sm-4">
        <img src={process.env.REACT_APP_API_HOST + picture.field_picture} alt={picture.field_picture_alt} className="img-fluid" />
      </div>
    ))
  }

  render() {
    return (
      <div id="bloc-instagram">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 offset-sm-1 text-center">
              <h1>{this.props.data.title}</h1>
            </div>
          </div>

          <div className="slideshow">
            <div className="row">
              {this.renderPictures()}
            </div>
          </div>

          <div className="row">
            <div className="col text-center">
              <a href={this.props.data.field_lien_uri} className="btn btn-square btn-milan-border btn-color-milan" rel="noopener noreferrer" target="_blank">{this.props.data.field_lien_title}</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    pictures: state.instagrams.items//.filter(item => item.langcode === state.locale.lang),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchInstagram: () => dispatch(fetchInstagram())
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Instagram))