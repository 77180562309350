export const LOCALE_SET = "LOCALE_SET"

export const FETCH_FAQS = 'FETCH_FAQS'
export const FETCH_FAQS_PENDING = 'FETCH_FAQS_PENDING'
export const FETCH_FAQS_REJECTED = 'FETCH_FAQS_REJECTED'
export const FETCH_FAQS_FULFILLED = 'FETCH_FAQS_FULFILLED'

export const FETCH_FAQS_CATEGORIES = 'FETCH_FAQS_CATEGORIES'
export const FETCH_FAQS_CATEGORIES_PENDING = 'FETCH_FAQS_CATEGORIES_PENDING'
export const FETCH_FAQS_CATEGORIES_REJECTED = 'FETCH_FAQS_CATEGORIES_REJECTED'
export const FETCH_FAQS_CATEGORIES_FULFILLED = 'FETCH_FAQS_CATEGORIES_FULFILLED'

export const FETCH_TERMS = 'FETCH_TERMS'
export const FETCH_TERMS_PENDING = 'FETCH_TERMS_PENDING'
export const FETCH_TERMS_REJECTED = 'FETCH_TERMS_REJECTED'
export const FETCH_TERMS_FULFILLED = 'FETCH_TERMS_FULFILLED'

export const FETCH_INSTAGRAM = 'FETCH_INSTAGRAM'
export const FETCH_INSTAGRAM_PENDING = 'FETCH_INSTAGRAM_PENDING'
export const FETCH_INSTAGRAM_REJECTED = 'FETCH_INSTAGRAM_REJECTED'
export const FETCH_INSTAGRAM_FULFILLED = 'FETCH_INSTAGRAM_FULFILLED'

export const FETCH_BLOC = 'FETCH_BLOC'
export const FETCH_BLOC_PENDING = 'FETCH_BLOC_PENDING'
export const FETCH_BLOC_REJECTED = 'FETCH_BLOC_REJECTED'
export const FETCH_BLOC_FULFILLED = 'FETCH_BLOC_FULFILLED'

export const FETCH_PRESSE = 'FETCH_PRESSE'
export const FETCH_PRESSE_PENDING = 'FETCH_PRESSE_PENDING'
export const FETCH_PRESSE_REJECTED = 'FETCH_PRESSE_REJECTED'
export const FETCH_PRESSE_FULFILLED = 'FETCH_PRESSE_FULFILLED'

export const FETCH_SPONSORS = 'FETCH_SPONSORS'
export const FETCH_SPONSORS_PENDING = 'FETCH_SPONSORS_PENDING'
export const FETCH_SPONSORS_REJECTED = 'FETCH_SPONSORS_REJECTED'
export const FETCH_SPONSORS_FULFILLED = 'FETCH_SPONSORS_FULFILLED'

export const FETCH_SPEAKERS = 'FETCH_SPEAKERS'
export const FETCH_SPEAKERS_PENDING = 'FETCH_SPEAKERS_PENDING'
export const FETCH_SPEAKERS_REJECTED = 'FETCH_SPEAKERS_REJECTED'
export const FETCH_SPEAKERS_FULFILLED = 'FETCH_SPEAKERS_FULFILLED'