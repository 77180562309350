import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';

export default class HeaderPacte extends Component {

  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    return (
      <div id="bloc-header-pacte" style={style}>
        <div className="container">
          <h1>{ReactHtmlParser(this.props.data.title)}</h1>
          <h2>{ReactHtmlParser(this.props.data.field_sous_titre)}</h2>
        </div>
      </div>
    )
  }
}