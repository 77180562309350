import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { fetchTerms } from '../actions/restAction';
import ReactHtmlParser from 'react-html-parser';

class Mentions extends Component {
  componentDidMount() {
    this.props.fetchPage()
    this.props.setHeaderOpacity()
  }

  render() {
    const { data, intl } = this.props

    if (data === undefined) {
      return <div className="loading"><i className="fas fa-spin fa-spinner-third"></i>{intl.formatMessage({ id: "loading" })}</div>
    }

    return (
      <div class="static-page">
        <div className="container">
          <h1>{ReactHtmlParser(data.title)}</h1>
          <div>{ReactHtmlParser(data.body)}</div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    data: state.terms.items.filter(item => item.langcode === state.locale.lang)[0],
  };
}


const mapDispatchToProps = (dispatch) => ({
  fetchPage: () => dispatch(fetchTerms()),
  setHeaderOpacity: () => dispatch({type: 'SET_OPAQUE'}),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Mentions))