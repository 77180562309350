import {FETCH_FAQS_CATEGORIES_PENDING, FETCH_FAQS_CATEGORIES_REJECTED, FETCH_FAQS_CATEGORIES_FULFILLED} from '../types'

const initialState = {
    fetching: true,
    rejected: false,
    items: []
}

const faqsCategories = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FAQS_CATEGORIES_PENDING:
            return {
                ...state,
                fetching: true,
                rejected: false,
                items: []
            }
        case FETCH_FAQS_CATEGORIES_REJECTED:
            return {
                ...state,
                fetching: false,
                rejected: true,
                items: []
            }

        case FETCH_FAQS_CATEGORIES_FULFILLED:
            return {
                ...state,
                fetching: false,
                rejected: false,
                items: action.payload.data
            }

        default:
            return state;
    }
}

export default faqsCategories
