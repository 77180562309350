import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'

import header from "./header";
import locale from "./locale";
import faqs from "./faqs";
import faqs_categories from "./faqs_categories";
import terms from "./terms";
import instagrams from "./instagram";
import blocs from "./blocs";
import presse from "./presse";
import sponsors from "./sponsors";
import speakers from "./speakers";

export default (history) => combineReducers({
  router: connectRouter(history),
  locale,
  header,
  faqs,
  faqs_categories,
  terms,
  instagrams,
  blocs,
  presse,
  sponsors,
  speakers,
});
