import React, { Component } from 'react'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import axios from 'axios'
import { injectIntl } from 'react-intl'
import ReactModal from 'react-modal'

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {},
      loading: false,
      thank: false,
      showModal: false,
      error: {},
    }
    this.position = [43.612965, 3.882064]
    this.zoom = 5
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.renderForm = this.renderForm.bind(this)
    this.renderText = this.renderText.bind(this)
    this.renderSelect = this.renderSelect.bind(this)
    this.renderTextarea = this.renderTextarea.bind(this)
    this.renderLoading = this.renderLoading.bind(this)
    this.renderError = this.renderError.bind(this)
  }

  componentWillMount() {
    const { intl } = this.props

    axios.get(intl.formatMessage({ id: "route.webform" })).then(result => {
      const inputs = {}
      Object.keys(result.data).forEach(name => inputs[name] = "")
      this.setState({form: result.data, ...inputs})
    });
  }

  createMapOptions(maps) {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      mapTypeControl: false
    };
  }

  renderThank() {
    return (
      <div className="thank">
        <i className="fal fa-envelope"></i><br />
        Votre message à bien été envoyé. <br />
        Nos équipes traitent votre demande et nous reviendrons prochainement vers vous.

        <button onClick={this.handleCloseModal} className="btn btn-link">Continuer ma visite</button>
      </div>
    )
  }

  renderLoading() {
    return (
    <div className="loading">
      <i className="fas fa-spin fa-spinner-third"></i>
    </div>
    )
  }

  renderForm() {
    /*
    if(this.state.thank) {
      return
    }
    */

    const formClass = 'form form-uppercase '// (this.state.loading) ? 'form form-loading' : 'form';

    return (
      <div className={formClass}>
        <form onSubmit={this.handleSubmit}>
        {
          Object.keys(this.state.form).map(name => this.renderInput(this.state.form[name]))
        }
        </form>
      </div>
    )
  }

  renderInput(field) {
    let type = field['#type']

    switch(type) {
      case 'webform_select_other':
      case 'select':
        return this.renderSelect(field)
      case 'webform_actions':
        return this.renderButton(field)
      case 'textarea':
        return this.renderTextarea(field)
      default:
        return this.renderText(field)
    }
  }

  renderSelect(field) {
    let id = field['#webform_id']
    let required = field['#webform_required']
    let key = field['#webform_key']
    let options = field['#options']
    let title = field['#title']

    let fieldClass = (this.state.error[key]) ? 'field has-error' : 'field'

    return (
      <div key={key} className={fieldClass}>
        <select
          id={id}
          name={key}
          onChange={this.handleChange}
          data-label={title}
          required={required}>
          <option>{field['#title'].toUpperCase()}</option>
          {
            Object.keys(options).map(key => this.renderOption(key, options[key]))
          }
        </select>
        <label htmlFor={id}>&nbsp;</label>
        {this.renderError(key)}
      </div>
    )
  }

  renderOption(key, option) {
    return <option key={key} value={key}>{option}</option>
  }

  renderText(field) {
    let id = field['#webform_id']
    let required = field['#webform_required']
    let key = field['#webform_key']
    let title = field['#title']

    let fieldClass = (this.state.error[key]) ? 'field has-error' : 'field'

    return (
      <div key={key} className={fieldClass}>
        <input
          id={id}
          name={key}
          type="text"
          value={this.state[key]}
          onChange={this.handleChange}
          placeholder=" "
          required={required}
        />
        <label htmlFor={id}>{title}</label>
        {this.renderError(key)}
      </div>
    )
  }

  renderError(key) {
    let error = this.state.error[key];
    return (error) ? <div className="error">{error}</div> : ''
  }

  renderTextarea(field) {
    let id = field['#webform_id']
    let required = field['#webform_required']
    let key = field['#webform_key']
    let title = field['#title']

    let fieldClass = (this.state.error[key]) ? 'field-textarea has-error' : 'field-textarea'

    return (
      <div key={key} className={fieldClass}>
        <label htmlFor={id}>{title}</label>
        <textarea
          id={id}
          name={key}
          value={this.state[key]}
          onChange={this.handleChange}
          required={required}
        ></textarea>
        {this.renderError(key)}
      </div>
    )
  }

  renderButton(field) {
    let id = field['#webform_id']
    let key = field['#webform_key']
    let label = field['#submit__label']

    return (
      <div key={key} className="field">
        <button
          id={id}
          name={key}
          type="submit"
          className="btn btn-square btn-3m-border btn-color-3m"
        >
          {label}
        </button>
      </div>
    )
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit (e) {
    e.preventDefault();
    this.setState({loading: true, showModal: true})

    axios.get('session/token').then(result => {
      if (200 !== result.status) {
        this.setState({loading: false, showModal: false})
        return
      }

      const csrfToken = result.data;

      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-CSRF-Token': csrfToken
      }

      const data = {
        'webform_id':'contact',
        ...this.state
      }

      axios.post(this.props.intl.formatMessage({ id: "route.webform_submit" }), data, {
        crossDomain: true,
        headers: headers
      })
      .then(
        (response) => {
          if(response.data.error) {
            this.setState({error: response.data.error, showModal: false})
          }

          if(response.data.sid) {
            this.setState({error: {}, thank: true})
          }

          this.setState({loading: false})
        },
        (error) => {
          this.setState({loading: false, showModal: false})
        }
      )
    });
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }
    
  render() {
    const {intl} = this.props

    return (
      <div id="bloc-contact">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>{intl.formatMessage({id: 'contact.title'})}</h2>
              
              <ReactModal 
              isOpen={this.state.showModal}
              className="Modal"
              overlayClassName="Overlay"  
              parentSelector={() => document.querySelector('#bloc-contact .container')}            
              >
                {(this.state.loading) ? this.renderLoading() : ''}
                {(this.state.thank) ? this.renderThank() : ''}
              </ReactModal>

              {this.renderForm()}
            </div>
            <div className="col">
              <h2>{intl.formatMessage({id: 'contact.location1'})}</h2>
              <p>{intl.formatMessage({id: 'contact.location2'})}</p>
              <p>{intl.formatMessage({id: 'contact.location3'})}</p>

              <div style={{ height: '383px', width: '100%' }}>
                <Map center={this.position} zoom={this.zoom}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                  />
                  <Marker position={this.position}>
                    <Popup>{intl.formatMessage({id: 'contact.location1'})}</Popup>
                  </Marker>
                </Map>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Contact)