import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { fetchPress } from '../actions/restAction'
import ReactHtmlParser from 'react-html-parser';

class PressRoom extends Component {
  componentDidMount() {
    this.props.setHeaderOpacity()
    this.props.fetchPress()
  }

  renderItems() {
    if (this.props.presse.length === 0) {
      return
    }

    const {intl} = this.props

    return this.props.presse.map(presse => (
      <div key={presse.uuid} className="col-sm-3">
        <div className="item">
          <div>
            <a href={process.env.REACT_APP_API_HOST + presse.field_fichier} target="_blank" rel="noopener noreferrer">
              <img src={process.env.REACT_APP_API_HOST + presse.field_picture} alt={presse.field_picture_alt} />
            </a>
          </div>
          <a href={process.env.REACT_APP_API_HOST + presse.field_fichier} className="title" target="_blank" rel="noopener noreferrer">{ReactHtmlParser(presse.title)}</a><br />
          <a href={process.env.REACT_APP_API_HOST + presse.field_fichier} className="download" target="_blank" rel="noopener noreferrer">{intl.formatMessage({id: "press.download"})}</a>
        </div>
      </div>
    ))
  }

  render() {
    const {intl} = this.props

    return (
      <div id="page-presse" className="container">
        <h1>{intl.formatMessage({id: "press.title"})}</h1>

        <div className="slideshow">
          <div className="row">
            {this.renderItems()}
          </div>
        </div>        
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    presse: state.presse.items.filter(item => item.langcode === state.locale.lang),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchPress: () => dispatch(fetchPress()),
  setHeaderOpacity: () => dispatch({type: 'SET_OPAQUE'}),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PressRoom))