import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import { injectIntl } from 'react-intl'

class Welcome extends Component {

  constructor(props) {
    super(props)
    this.hours = moment('7/10/2019', 'DD/MM/YYYY').diff(new Date(), 'hours');
  }

  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    return (
      <div id="bloc-welcome" style={style}>
        <div className="container">
          <img src={process.env.REACT_APP_API_HOST + this.props.data.field_illustration} alt={this.props.data.field_illustration_alt} />
          <h1>{ReactHtmlParser(this.props.data.title)}</h1>
          <h2>{ReactHtmlParser(this.props.data.field_sous_titre)}</h2>
          <h3>{ReactHtmlParser(this.props.data.body)}</h3>
          {
            (this.hours >0) ? <div className="counter">{this.props.intl.formatMessage({id: 'days'})} - {Math.ceil(this.hours / 24)}</div> : ''
          }
        </div>
      </div>
    )
  }
}
export default (injectIntl(Welcome))