import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { fetchSpeakers } from '../actions/restAction'
import ReactHtmlParser from 'react-html-parser';

class Speakers extends Component {
  componentDidMount() {
    this.props.setHeaderOpacity()
    this.props.fetchSpeakers()
  }

  renderItems() {
    if (this.props.speakers.length === 0) {
      return
    }

    return this.props.speakers.map(speaker => (
      <div key={speaker.uuid} className="col-sm-6">
        <div className="speaker">
          <div className="photo">
            <img src={process.env.REACT_APP_API_HOST + speaker.field_picture} alt={speaker.field_picture_alt} className="img" />
          </div>

          <div className="infos">
            <div className="name">{speaker.title}</div>
            <div className="field_titre">{speaker.field_localite}</div>
            <div className="field_location">{speaker.field_titre}</div>
            <div className="field_prix">{ReactHtmlParser(speaker.field_prix.split('$').join('<br />'))}</div>
            <div className="field_body">{ReactHtmlParser(speaker.body)}</div>
            <div className="field_citation">&laquo; {speaker.field_citation} &raquo;</div>
          </div>
        </div>
      </div>
    ))
  }

  render() {
    const {intl} = this.props

    return (
      <div id="page-program" className="container">
        
        <div class="submenu">
          <NavLink to={intl.formatMessage({ id: "route.program" })} exact="true" className="nav-link nav-main" activeClassName="active">{intl.formatMessage({ id: "header.program" })}</NavLink>
          <NavLink to={intl.formatMessage({ id: "route.speakers" })} exact="true" className="nav-link nav-main" activeClassName="active">{intl.formatMessage({ id: "header.speakers" })}</NavLink>
        </div>

        <div className="speakers row">
          {this.renderItems()}
        </div>  
      </div>
    )

  }
}

function mapStateToProps(state) {
  return {
    speakers: state.speakers.items.filter(item => item.langcode === state.locale.lang),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchSpeakers: () => dispatch(fetchSpeakers()),
  setHeaderOpacity: () => dispatch({ type: 'SET_OPAQUE' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Speakers))