import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

export default class ABientot extends Component {
  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    return (
      <div id="bloc-abientot" style={style}>
        <h1>{ReactHtmlParser(this.props.data.title)}</h1>
        <h2>{ReactHtmlParser(this.props.data.field_sous_titre)}</h2>
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="https://www.facebook.com/MontpellierMediterraneeMetropole/" target="_blank" rel="noreferrer noopener"><i className="fab fa-facebook-f"></i></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://twitter.com/Montpellier3m" target="_blank" rel="noreferrer noopener"><i className="fab fa-twitter"></i></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://www.instagram.com/montpelliernow/" target="_blank" rel="noreferrer noopener"><i className="fab fa-instagram"></i></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://www.youtube.com/channel/UCwqXE4diVuawA9VH3IfsrVw" target="_blank" rel="noreferrer noopener"><i className="fab fa-youtube"></i></a>
          </li>
        </ul>
      </div>
    )
  }
}