import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { switchLocale } from '../actions/locale'
import { injectIntl } from 'react-intl'
import $ from 'jquery'

import logo from '../assets/img/logo-header.png'
import flagFr from '../assets/flags/fr.svg'
import flagEn from '../assets/flags/gb.svg'
import flagEs from '../assets/flags/es.svg'

class Header extends Component {

    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        $('#navbarSupportedContent').on('show.bs.collapse', () => {
            $('header').addClass('opaque')
            $('header').addClass('open')
        })    
        $('#navbarSupportedContent').on('hidden.bs.collapse', () => {
            $('header').removeClass('open')
            this.handleScroll()
        })                
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if ($(this.ref.current).hasClass('force-opaque')) {
            return
        }

        if ($(this.ref.current).hasClass('open')) {
            return
        }

        if (window.scrollY <= 20) {
            $(this.ref.current).removeClass('opaque')
        } else {
            $(this.ref.current).addClass('opaque')
        }
    }

    getFlag(lang) {
        let flag = flagFr
        switch (lang) {
            case 'en':
                flag = flagEn
                break
            case 'es':
                flag = flagEs
                break
            default:
                flag = flagFr
                break
        }
        return flag
    }

    render() {
        const { lang, intl, switchLocale, transparent } = this.props

        return (
            <header ref={this.ref} className={(transparent) ? '' : 'force-opaque opaque'}>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <a className="navbar-brand" href="https://www.montpellier3m.fr/" target="_blank" rel="noreferrer noopener"><img src={logo} alt={intl.formatMessage({ id: "header.sitename" })} /></a>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav navbar-primary ml-auto mr-auto">
                                <li className="nav-item">
                                    <NavLink to={intl.formatMessage({ id: "route.homepage" })} className="nav-link nav-main" exact={true} activeClassName="active">{intl.formatMessage({ id: "header.homepage" })}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={intl.formatMessage({ id: "route.thepacte" })} className="nav-link nav-main" activeClassName="active">{intl.formatMessage({ id: "header.thepacte" })}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={intl.formatMessage({ id: "route.newsroom" })} className="nav-link nav-main" activeClassName="active">{intl.formatMessage({ id: "header.newsroom" })}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={intl.formatMessage({ id: "route.program" })} className="nav-link nav-main" activeClassName="active">{intl.formatMessage({ id: "header.program" })}</NavLink>
                                </li>                                
                                <li className="nav-item">
                                    <NavLink to={intl.formatMessage({ id: "route.faq" })} className="nav-link nav-main" activeClassName="active">{intl.formatMessage({ id: "header.faq" })}</NavLink>
                                </li>
                            </ul>

                            <ul className="navbar-nav navbar-secondary ml-4">
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.facebook.com/MontpellierMilanPact2019" target="_blank" rel="noreferrer noopener"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://twitter.com/MtpMilanPact19" target="_blank" rel="noreferrer noopener"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.instagram.com/montpelliernow/" target="_blank" rel="noreferrer noopener"><i className="fab fa-instagram"></i></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.youtube.com/channel/UCwqXE4diVuawA9VH3IfsrVw" target="_blank" rel="noreferrer noopener"><i className="fab fa-youtube"></i></a>
                                </li>
                                <li className="nav-item dropdown">
                                    <button className="btn btn-link nav-link dropdown-toggle" id="navbarDropdown" data-toggle="dropdown">
                                        <img src={this.getFlag(lang)} alt="FR" width="30" height="20"/>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <button className="btn btn-link dropdown-item" onClick={() => switchLocale("fr", "/")}><img src={flagFr} alt="FR" width="30" height="20"/></button>
                                        <button className="btn btn-link dropdown-item" onClick={() => switchLocale("en", "/en")}><img src={flagEn} alt="EN" width="30" height="20"/></button>
                                        <button className="btn btn-link dropdown-item" onClick={() => switchLocale("es", "/es")}><img src={flagEs} alt="ES" width="30" height="20"/></button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </header >
        )
    }
}

function mapStateToProps(state) {
    return {
        lang: state.locale.lang,
        transparent: state.header.transparent,
    };
}

export default connect(mapStateToProps, { switchLocale })(injectIntl(Header))
