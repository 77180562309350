import React, { Component } from 'react'
import { Route, Switch } from "react-router-dom"
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

import Header from './component/Header'
import Footer from './component/Footer'

import messages from './messages'
import routes from './routes'
import IntlCookieConsent from './component/IntlCookieConsent';

class App extends Component {

  computeRoutes(routes) {
    let reactRoutes = [];

    for (var name in routes) {
      for (var lang in routes[name].intl) {
        reactRoutes.push({
          path: routes[name].intl[lang],
          exact: routes[name].exact,
          component: routes[name].component
        })
      }
    }

    return reactRoutes
  }

  computesRoutesToMessages(messages, routes, lang) {
    const computedMessages = { ...messages }

    for (var name in routes) {
      let route = routes[name]
      computedMessages[lang][`route.${name}`] = route.intl[lang]
    }

    return computedMessages[lang]
  }

  render() {

    const { lang } = this.props

    return (
      <IntlProvider locale={lang} messages={this.computesRoutesToMessages(messages, routes, lang)}>
        <div className="App">
          <Header />

          <div className="main">
            <Switch>
              {
                this.computeRoutes(routes).map((route, i) => {
                  return <Route key={i} {...route} />
                })
              }
            </Switch>
          </div>
          <Footer />
          <IntlCookieConsent />
        
        </div>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.locale.lang
  };
}

export default connect(mapStateToProps)(App);