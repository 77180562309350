import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

export default class Mufpp extends Component {

  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    return (
      <div id="bloc-mufpp" style={style}>
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="mufpp">
                <h1>{this.props.data.title}</h1>
                {ReactHtmlParser(this.props.data.body)}
                <a href={this.props.data.field_lien_uri} className="link" rel="noopener noreferrer" target="_blank">{this.props.data.field_lien_title}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}