import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

export default class Sala extends Component {
  render() {
    return (
      <div id="bloc-sala">
        <div className="container">
          <div className="row">

            <div className="col-lg-6">
              <img src={process.env.REACT_APP_API_HOST + this.props.data.field_picture} className="img-fluid" alt={this.props.data.field_picture_alt} />
            </div>
            <div className="col-lg-6">
              <div className="presentation">
                <h2>{this.props.data.title}</h2>
                <div className="quote">
                  {ReactHtmlParser(this.props.data.body)}
                </div>
                <em>{this.props.data.field_sous_titre}</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
