import React, { Component, Fragment } from 'react'
import ReactHtmlParser from 'react-html-parser';

export default class HeaderRegister extends Component {

  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    return (
      <Fragment>
        <div id="bloc-header-register" style={style}>
          <div className="container">
            <h1>{ReactHtmlParser(this.props.data.title)}</h1>
            <h2>{ReactHtmlParser(this.props.data.field_sous_titre)}</h2>
          </div>
        </div>

        <div id="bloc-intro-register">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 offset-sm-2">
                <h1>{ReactHtmlParser(this.props.data.body)}</h1>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}