import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'

import { fetchBloc } from '../actions/restAction'

class Program extends Component {
  componentDidMount() {
    this.props.setHeaderOpacity()
    this.props.fetchBloc('programme', this.props.fetched)
  }

  renderItems() {
    if (this.props.pages.length === 0) {
      return
    }

    const { pages } = this.props

    return pages.map(page => (
      <div key={page.uuid} className="col-12">
        <div className="item text-center">
            <a href={process.env.REACT_APP_API_HOST + page.field_fichier} target="_blank" rel="noopener noreferrer">
              <img src={process.env.REACT_APP_API_HOST + page.field_picture} alt={page.field_picture_alt} className="img-fluid" />
            </a>
        </div>
      </div>
    ))
  }

  render() {
    
    const { intl, pages} = this.props

    if (pages === undefined) {
      return <div className="loading"><i className="fas fa-spin fa-spinner-third"></i>{intl.formatMessage({ id: "loading" })}</div>
    }

    return (
      <div id="page-program" className="container">

        <div class="submenu">
          <NavLink to={intl.formatMessage({ id: "route.program" })} exact="true" className="nav-link nav-main" activeClassName="active">{intl.formatMessage({ id: "header.program" })}</NavLink>
          <NavLink to={intl.formatMessage({ id: "route.speakers" })} exact="true" className="nav-link nav-main" activeClassName="active">{intl.formatMessage({ id: "header.speakers" })}</NavLink>
        </div>

        <div className="slideshow">
          <div className="row">
            {this.renderItems()}
          </div>
        </div>    
      </div>
    )

  }
}

function mapStateToProps(state) {
  return {
    fetched: state.blocs.fetched,
    pages: state.blocs.blocs.filter(item => (item.langcode === state.locale.lang && item.field_nom === 'programme'))
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchBloc: (bloc, fetched) => dispatch(fetchBloc(bloc, fetched)),
  setHeaderOpacity: () => dispatch({ type: 'SET_OPAQUE' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Program))