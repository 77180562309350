import {FETCH_SPEAKERS_PENDING, FETCH_SPEAKERS_REJECTED, FETCH_SPEAKERS_FULFILLED} from '../types'

const initialState = {
    fetching: true,
    rejected: false,
    items: []
}

const speakers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SPEAKERS_PENDING:
            return {
                ...state,
                fetching: true,
                rejected: false,
                items: []
            }
        case FETCH_SPEAKERS_REJECTED:
            return {
                ...state,
                fetching: false,
                rejected: true,
                items: []
            }

        case FETCH_SPEAKERS_FULFILLED:
            return {
                ...state,
                fetching: false,
                rejected: false,
                items: action.payload.data
            }

        default:
            return state;
    }
}

export default speakers
