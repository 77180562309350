import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { fetchBloc } from '../actions/restAction'
import { retrieveBloc, retrieveBlocs } from '../bloc'
import HeaderRegister from './blocs/HeaderRegister'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser';
import ABientot from './blocs/ABientot'
import Contact from './blocs/Contact'

const blocs = ['header_register','register_type', 'abientot']

class Register extends Component {
  constructor(props) {
    super(props)

    this.state = {
      type: null,
      form: null,
      fetching: false,
      thank: false,
      hasError: false,
      error: {},
    }

    
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeArray = this.handleChangeArray.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.renderForm = this.renderForm.bind(this)
    this.renderText = this.renderText.bind(this)
    this.renderSelect = this.renderSelect.bind(this)
    this.renderTextarea = this.renderTextarea.bind(this)
    this.renderLoading = this.renderLoading.bind(this)
    this.renderError = this.renderError.bind(this)
    this.displayTypes = this.displayTypes.bind(this)
    this.scrollToMyRef = this.scrollToMyRef.bind(this)

    this.ref = null
  }

  componentDidMount() {
    blocs.forEach(item => this.props.fetchBloc(item, this.props.fetched))
    this.props.setHeaderOpacity()
  }

  scrollToMyRef = () => {
    window.scrollTo(0, this.findPos(this.myRef) - 150)
  }

  findPos = obj => {
    var curtop = 0;
    if (obj.offsetParent) {
      do {
          curtop += obj.offsetTop;
      } while (obj = obj.offsetParent);
      return curtop;
    }
  }

  displayTypes(types) {
    return (
      <div id="bloc-types-register">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="row">
                { types.map(type => this.displayType(type)) }
              </div>
            </div>
          </div>
          <div className="row" ref={ ref => this.myRef = ref }>
            { this.renderForm() }
          </div>
        </div>
      </div>
    )
  }

  displayType(type) {
    const divClass = (this.state.type === type.field_tags) ? 'current' : '';

    return (
      <div key={type.uuid} className="col-md-6 mb-4" onClick={() => this.loadForm(type)} data-tags={type.field_tags}>
        <div className={"type " + divClass}>
          <div className="picto">
            <img src={ process.env.REACT_APP_API_HOST + type.field_picture } alt="" className="black" />
            <img src={ process.env.REACT_APP_API_HOST + type.field_illustration } alt="" className="blue"/>
          </div>

          <h3>{ ReactHtmlParser(type.field_sous_titre) }</h3>
          <h2>{ ReactHtmlParser(type.title) }</h2>
        </div>
      </div>
    )
  }

  loadForm(type) {
    const { intl } = this.props
    this.setState({
      fetching: true,
      type: type.field_tags
    })
    axios.get(intl.formatMessage({ id: "route." + type.field_tags })).then(result => {
      const inputs = {}
      this.setState({
        form: result.data, 
        fetching: false,
        hasError: false,
        ...inputs
      })
      this.scrollToMyRef()
    });
  }

  renderFormError() {
    return (
      <div className="thank">
        <i className="fal fa-frown"></i><br />
        {ReactHtmlParser(this.props.intl.formatMessage({ id: "register.error" }))}
      </div>
    )
  }
  renderThank() {
    return (
      <div className="thank">
        <i className="fal fa-envelope"></i><br />
        {ReactHtmlParser(this.props.intl.formatMessage({ id: "register.thank" }))}
      </div>
    )
  }

  renderLoading() {
    return (
    <div className="text-center loading">
      <i className="fas fa-spin fa-spinner-third"></i>
    </div>
    )
  }

  renderForm() {
    if(this.state.fetching) {
      return this.renderLoading()
    }

    if(this.state.thank) {
      return this.renderThank()
    }

    if(this.state.form === null) {
      return
    }

    const formClass = 'form m-auto'

    return (
      <div key={"renderFormWrapper" + this.state.type + "Wrapper"} className={formClass}>
        {(this.state.hasError) ? this.renderFormError() : ''}
        <form key={"renderForm" + this.state.type} id="register" onSubmit={this.handleSubmit}>
        {
          Object.keys(this.state.form).map(name => this.renderInput(this.state.form[name]))
        }
        </form>
      </div>
    )
  }

  renderInput(field) {
    const type = field['#type']
    const key = field['#webform_key']

    if(key.substr(-16,15) === 'themes1_choices' || key.substr(-16,15) === 'themes2_choices') {
      return
    }

    switch(type) {
      case 'webform_select_other':
      case 'select':      
        return this.renderSelect(field)
      case 'webform_markup':
      case 'markup':
        return this.renderMarkup(field)
      case 'webform_actions':
        return this.renderButton(field)        
      case 'webform_custom_composite':
        return this.renderComposite(field)
      case 'textarea':
        return this.renderTextarea(field)
      case 'checkbox':
        return this.renderCheckbox(field)        
      case 'radios':
        return this.renderRadios(field)                         
      default:
        return this.renderText(field)
    }
  }

  renderRadiosAsCheckboxes(field) {
    let key = field['#webform_key']
    let options = field['#options']
    let title = field['#title']

    let fieldClass = (this.state.error[key]) ? 'field field-checkbox field-checkboxes has-error' : 'field field-checkbox field-checkboxes '

    //let addTitle = ''
    /*
    if(key.substr(-8,7) === 'themes1') {
      addTitle = '<h4>Vos préférences</h4>'
    }
    */
//        {ReactHtmlParser(addTitle)}
    return (
      <div key={key} id={key} className={fieldClass}>

        <span key={"label_" + key} className="label">{title}</span>
        {Object.keys(options).map(val => this.renderRadioAsCheckbox(key, val, options[val]))}
        {this.renderError(key)}
      </div>
    )
  }

  renderRadios(field) {
    let key = field['#webform_key']
    let options = field['#options']
    let title = field['#title']

    let fieldClass = (this.state.error[key]) ? 'field field-radio has-error' : 'field field-radio '

    if(key.substr(-8,6) === 'themes') {
      return this.renderRadiosAsCheckboxes(field);
    }

    return (
      <div key={key} id={key} className={fieldClass}>
        <span key={"label_" + key} className="label">{title}</span>
        {Object.keys(options).map(val => this.renderRadio(key, val, options[val]))}
        {this.renderError(key)}
      </div>
    )
  }

  renderRadio(name, key, option) {
    return (
      <label key={"radio-" + name+ key} htmlFor={"radio-" + name + key}>
        <input id={"radio-" + name+ key} key={name+key} type="radio" name={name} value={key} onClick={this.handleChange} />
        {option}
      </label>
    )
  }

  renderRadioAsCheckbox(name, key, option) {
    return (
      <label key={"radio-" + name+ key} htmlFor={"radio-" + name + key}>
        <input id={"radio-" + name+ key} key={name+key} type="checkbox" name={name} value={key} onClick={this.handleChangeArray} />
        {option}
      </label>
    )
  }

  renderSelect(field) {
    let id = field['#webform_id']
    let required = field['#webform_required']
    let key = field['#webform_key']
    let options = field['#options']
    let title = field['#title']

    let fieldClass = (this.state.error[key]) ? 'field has-error' : 'field'

    return (
      <div key={key} className={fieldClass}>
        <select
          id={id}
          name={key}
          onChange={this.handleChange}
          data-label={title}
          required={required}>
          <option>{field['#title']}</option>
          {
            Object.keys(options).map(key => this.renderOption(key, options[key]))
          }
        </select>
        <label htmlFor={id}>&nbsp;</label>
        {this.renderError(key)}
      </div>
    )
  }

  renderOption(key, option) {
    return <option key={key} value={key}>{option}</option>
  }

  renderText(field) {
    let id = field['#webform_id']
    let required = field['#webform_required']
    let key = field['#webform_key']
    let title = field['#title']
    let type = 'text'

    switch(field['#type']) {
      default: 
        type = field['#type']
    }

    let fieldClass = (this.state.error[key]) ? 'field has-error' : 'field'

    return (
      <div key={key} className={fieldClass}>
        <input
          id={id}
          name={key}
          type={type}
          value={this.state[key]}
          onChange={this.handleChange}
          placeholder=" "
          required={required}
        />
        <label htmlFor={id}>{title}</label>
        {this.renderError(key)}
      </div>
    )
  }

  renderMarkup(field) {
    return (
      <div id={field['#webform_id']} key={field['#webform_id']}>
        { ReactHtmlParser(field['#markup']) }
      </div>
    )
  }  

  renderError(key) {
    let error = this.state.error[key];
    return (error) ? <div className="error">{error}</div> : ''
  }

  renderTextarea(field) {
    let id = field['#webform_id']
    let required = field['#webform_required']
    let key = field['#webform_key']
    let title = field['#title']

    let fieldClass = (this.state.error[key]) ? 'field-textarea has-error' : 'field-textarea'

    return (
      <div key={key} className={fieldClass}>
        <label htmlFor={id}>{title}</label>
        <textarea
          id={id}
          name={key}
          value={this.state[key]}
          onChange={this.handleChange}
          required={required}
        ></textarea>
        {this.renderError(key)}
      </div>
    )
  }

  renderCheckbox(field) {
    let id = field['#webform_id']
    let required = field['#webform_required']
    let key = field['#webform_key']
    let title = field['#title']

    let fieldClass = 'field-checkbox'

    return (
      <div key={key} className={fieldClass}>
        <input 
          id={id}
          type="checkbox" 
          value="1"
          name={key}
          onChange={this.handleChange}
          required={required}        
        />
        <label htmlFor={id}>{title}</label>
        {this.renderError(key)}
      </div>
    )
  }

  renderButton(field) {
    let id = field['#webform_id']
    let key = field['#webform_key']
    let label = field['#submit__label']

    return (
      <div key={key} className="field field-button">
        <button
          id={id}
          name={key}
          type="submit"
          className="btn btn-square btn-3m-border btn-color-3m"
        >
          {label}
        </button>
      </div>
    )
  }

  renderComposite(field) {
    let id = field['#webform_id']
    let key = field['#webform_key']
    let elements = field['#webform_composite_elements']
    let renderElements = []

    for(let i = 0;i<3;i++) {
      renderElements.push(
        <button key={id+"__"+i} className={"btn btn-link" + ((i!==0) ? ' collapsed' : '')} type="button" data-toggle="collapse" data-target={`#collapse${i}`}>
          <h4>{this.props.intl.formatMessage({ id: "register.participant" })} {i+1} <i className="fal fa-chevron-down"></i><i className="fal fa-chevron-up"></i></h4>
        </button>        
      )

      let divCollapse = (
        <div id={`collapse${i}`} className={"collapse" + ((i===0) ? ' show' : '')}>
        {
          Object.keys(elements).map(name => {
            elements[name]['#webform_id'] =  `edit-${key}-${i}-${name}`
            elements[name]['#webform_key'] = `${key}[${i}][${name}]`
            return this.renderInput(elements[name])
          })
        }
        </div>
      )

      renderElements.push(divCollapse)
    }

    return (<Fragment key={id}>{renderElements}</Fragment>)
  }  

  handleChangeArray(e) {
    const {value, name, checked} = e.target

    let oldValues = this.state[name]
    if(oldValues === undefined) {
      oldValues = []
    }

    if(checked) {
      if(oldValues.length === 2) {
        e.target.checked = false;
        return false;
      }

      this.setState({
        [name]: [...oldValues, value]
      })
    } else {
      this.setState({
        [name]: [...oldValues].filter(i => i !== value)
      })
    }
  }

  handleChange(e) {
    const {value, name} = e.target

    this.setState({
      [name]: value
    })
  }

  isValid() {
    const canBeEmpty = [
      'actions',
      'intro',
      'header1',
      'header2',
      'adresse_complement',
      'website',
      'telephone',
      'participants',
      'preferences',
      'legal',
      'city_and_mufpp',
      'more_than_3_participant'
    ]

    const requiredParticipant = [
      'firstname',
      'lastname',
      'email',
      'function',
      'language'
    ]

    let hasError = false
    let hasPartipant = false
    let mustHaveParticipant = false
    let foundErrors = {}

    Object.keys(this.state.form).forEach(a => {
      //console.log(a, this.state[a], this.state.form[a])

      if((this.state[a] === undefined || this.state[a].trim() === '') && !canBeEmpty.includes(a)) {
        foundErrors[a] = this.props.intl.formatMessage({ id: "register.required" })
        hasError = true
      }

      if(a === 'participants') {
        mustHaveParticipant = true
        //Verifier que les champs obligatoire d'un participant sont rempli si un participant est partiellement commencé
        for(let i = 0;i<3;i++) {
          if(
            this.state[`participants[${i}][firstname]`] !== undefined ||
            this.state[`participants[${i}][lastname]`] !== undefined ||
            this.state[`participants[${i}][email]`] !== undefined ||
            this.state[`participants[${i}][phone]`] !== undefined ||
            this.state[`participants[${i}][function]`] !== undefined ||
            this.state[`participants[${i}][language]`] !== undefined ||
            this.state[`participants[${i}][themes1]`] !== undefined ||
            this.state[`participants[${i}][themes2]`] !== undefined
          ) {
            hasPartipant = true
            requiredParticipant.forEach(a => {
              if((this.state[`participants[${i}][${a}]`] === undefined || this.state[`participants[${i}][${a}]`].trim() === '')) {
                foundErrors[`participants[${i}][${a}]`] = this.props.intl.formatMessage({ id: "register.required" })
                hasError = true
              }
            })

            const themes1 = this.state[`participants[${i}][themes1]`]
            
            if(themes1 === undefined || themes1.length !==2) {
              foundErrors[`participants[${i}][themes1]`] = this.props.intl.formatMessage({ id: "register.wrong_count" })
              hasError = true
            }
  
            const themes2 = this.state[`participants[${i}][themes2]`]

            if(themes2 === undefined || themes2.length !==2) {
              foundErrors[`participants[${i}][themes2]`] = this.props.intl.formatMessage({ id: "register.wrong_count" })
              hasError = true
            }
          }
        }
      }
    })

    if(mustHaveParticipant && !hasPartipant) {
      let a = 'participants[0][firstname]'
      foundErrors[a] = this.props.intl.formatMessage({ id: "register.required" })
      hasError = true
    }

    if(hasError > 0) {
      this.setState({
        error: foundErrors,
        hasError
      })

      return false;
    }

    this.setState({
      hasError
    })
    
    return true;
  }


  handleSubmit (e) {
    e.preventDefault();

    this.scrollToMyRef()

    if(!this.isValid()) {
      return
    }

    this.setState({fetching: true})

    axios.get('session/token').then(result => {
      if (200 !== result.status) {
        this.setState({fetching: false})
        return
      }

      const csrfToken = result.data;

      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-CSRF-Token': csrfToken
      }

      const datas = this.buildFormData();

      axios.post(this.props.intl.formatMessage({ id: "route.webform_submit" }), datas.formData, {
        crossDomain: true,
        headers: headers
      })
      .then(
        (response) => {
          if(response.data.error) {
            this.setState({error: response.data.error})
          }

          if(response.data.sid) {
            datas.emails.forEach(email => this.submitEmail(datas.formData.webform_id, response.data.sid, email));
            this.setState({error: {}, thank: true})
          }

          this.setState({fetching: false})
        },
        (error) => {
          this.setState({fetching: false})
        }
      )
    });
  }

  submitEmail(webform, sid, email) {
    
    console.log({webform, sid, email})

    if(!email) {
      return
    }

    axios.get('session/token').then(result => {
      if (200 !== result.status) {
        return
      }

      const csrfToken = result.data;

      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-CSRF-Token': csrfToken
      }

      const datas = {webform_id: 'confirmation', webform, sid, email}

      axios.post(this.props.intl.formatMessage({ id: "route.webform_submit" }), datas, {
        crossDomain: true,
        headers: headers
      })
    });
  }

  buildFormData() {
    let formData = {}
    let participants = []
    let emails = []

    switch(this.state.type) {
      case "inscription_elu":
        formData =  {
          "webform_id":this.state.type,
          "city":this.state.city,
          "country":this.state.country,
          "adresse":this.state.adresse,
          "adresse_complement":this.state.adresse_complement,
          "city_and_mufpp": this.state.city_and_mufpp,
          "more_than_3_participant": this.state.more_than_3_participant,
        };

        for(let i = 0;i<3;i++) {
          let participant = {
            "firstname": this.state[`participants[${i}][firstname]`],
            "lastname": this.state[`participants[${i}][lastname]`],
            "email": this.state[`participants[${i}][email]`],
            "phone": this.state[`participants[${i}][phone]`],
            "function": this.state[`participants[${i}][function]`],
            "language": this.state[`participants[${i}][language]`],
            "themes1_choices": this.state[`participants[${i}][themes1]`] ? this.state[`participants[${i}][themes1]`].join(' - ') : null,
            "themes2_choices": this.state[`participants[${i}][themes2]`] ? this.state[`participants[${i}][themes2]`].join(' - ') : null,
          }

          if(this.state[`participants[${i}][email]`] != "") {
            emails.push(this.state[`participants[${i}][email]`])
          }

          participants.push(participant);
        }

        formData.participants = participants;

        return {formData, emails}

      case "inscription_prive":
      case "inscription_autre":
        formData =  {
          "webform_id":this.state.type,
          "organisation":this.state.organisation,
          "country":this.state.country,
          "adresse":this.state.adresse,
          "adresse_complement":this.state.adresse_complement,
          "more_than_3_participant": this.state.more_than_3_participant,
        };

        for(let i = 0;i<3;i++) {
          let participant = {
            "firstname": this.state[`participants[${i}][firstname]`],
            "lastname": this.state[`participants[${i}][lastname]`],
            "email": this.state[`participants[${i}][email]`],
            "phone": this.state[`participants[${i}][phone]`],
            "function": this.state[`participants[${i}][function]`],
            "language": this.state[`participants[${i}][language]`],
            "themes1_choices": this.state[`participants[${i}][themes1]`] ? this.state[`participants[${i}][themes1]`].join(' - ') : null,
            "themes2_choices": this.state[`participants[${i}][themes2]`] ? this.state[`participants[${i}][themes2]`].join(' - ') : null,
          }

          if(this.state[`participants[${i}][email]`] != "") {
            emails.push(this.state[`participants[${i}][email]`])
          }

          participants.push(participant);
        }

        formData.participants = participants;

        return {formData, emails}

      case "inscription_presse":
        formData =  {
          "webform_id":this.state.type,
          "redaction":this.state.redaction,
          "numero_presse":this.state.numero_presse,
          "country":this.state.country,
          "adresse":this.state.adresse,
          "adresse_complement":this.state.adresse_complement,
          "website": this.state.website,
          "prenom": this.state.prenom,
          "nom": this.state.nom,
          "email": this.state.email,
          "telephone": this.state.telephone,
          "fonction": this.state.fonction,
          "language": this.state.language,
    
        };

        emails.push(this.state.email)

        return {formData, emails}

      default:
        return {}
    }
  }
    
  render() {
    //blocs
    const { header, types, abientot } = this.props

    return (
      <div id="page-register">
        <HeaderRegister data={header} />
        { this.displayTypes(types) }
        <ABientot data={abientot} />
        <Contact />        
      </div>
    )
  }
}

function mapStateToProps(state) {
  let map = {
    fetched: state.blocs.fetched,
    'header': retrieveBloc('header_register', state),
    'abientot': retrieveBloc('abientot', state),
    'types': retrieveBlocs('register_type', state),
  }
  
  return map
}

const mapDispatchToProps = (dispatch) => ({
  fetchBloc: (bloc, fetched) => dispatch(fetchBloc(bloc, fetched)),
  setHeaderOpacity: () => dispatch({ type: 'SET_OPAQUE' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Register))