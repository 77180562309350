import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';

export default class Venir extends Component {

  render() {
    var style = {
      backgroundImage: 'url(' + process.env.REACT_APP_API_HOST + this.props.data.field_picture + ')',
    };

    return (
      <div id="bloc-venir" style={style}>

        <a href={process.env.REACT_APP_API_HOST + this.props.data.field_fichier} rel="noopener noreferrer" target="_blank">
          {ReactHtmlParser(this.props.data.title)}<br />
          {ReactHtmlParser(this.props.data.field_sous_titre)}
        </a>

        <div className="office-tourisme">
          {ReactHtmlParser(this.props.data.body)}<br />
          <img src={process.env.REACT_APP_API_HOST + this.props.data.field_illustration} alt={this.props.data.field_illustration_alt} />
        </div>

      </div>
    )
  }
}